.chat-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background-color: #1e1e1e;
    color: white;
}

.messages {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    background-color: #1e1e1e;
}

.message {
    margin: 10px 0;
    padding: 10px;
    background-color: #2e2e2e;
    border-radius: 4px;
    word-wrap: break-word;
}

.message strong {
    color: lightblue; 
}

.message-form {
    display: flex;
    padding: 20px;
    background-color: #2e2e2e;
}

.message-form input {
    flex: 1;
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
    border: 1px solid #4b4b4b;
    background-color: #3b3b3b;
    color: white;
}

.message-form input::placeholder {
    color: #ccc;
}

.message-form button {
    background-color: lightblue;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.message-form button:hover {
    background-color: pink;
}

.profile-link {
    background-color: lightblue;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    text-decoration: none;
    text-align: center;
    margin-top: 10px;
}

.profile-link:hover {
    background-color: pink;
}

.timestamp-text {
    color: #ccc;
    font-size: 0.76rem;
}