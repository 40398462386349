.wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin: 0;
    color: white;
}

.background-image {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: brightness(0.75);
}

.auth-form-container {
    background-color: #2e2e2e;
    width: 25rem;
    height: auto;
    padding: 3rem;
    border-radius: 0.7rem;
    margin: 0 auto;
    text-align: center;
}

input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    border: 1px solid #4b4b4b;
    background-color: #3b3b3b;
    color: white;
}

input::placeholder {
    color: #ccc;
}

button {
    background-color: lightblue;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

button:hover {
    background-color: pink;
}

.error-message {
    color: red;
    font-size: 0.9em;
}

.toggle-link {
    color: lightblue;
    cursor: pointer;
}

.toggle-link:hover {
    text-decoration: underline;
}
