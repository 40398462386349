.landing-container {
    background-color: #1e1e1e;
    color: white;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.split-box {
    display: flex;
    justify-content: space-between;
    width: 60%;
    padding: 20px;
    background-color: #2e2e2e;
    border-radius: 8px;
}

.login-section, .guest-section {
    flex: 1;
    text-align: center;
}

.divider {
    width: 2px;
    background-color: pink; /* Trans flag color */
}

.action-button {
    background-color: lightblue; /* Trans flag color */
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.action-button:hover {
    background-color: pink;
}

.footer {
    position: absolute;
    bottom: 1rem;
    width: 80vw;
    text-align: center;
    background-color: #2e2e2e;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-weight: bold;
    border-radius: 0.3rem;
}

.footer a {
    color: white;
    text-decoration: none;
    margin: 0;
    padding: 0;
}