.profile-container {
    background-color: #2e2e2e;
    padding: 40px;
    border-radius: 8px;
    width: 400px;
    margin: 50px auto;
    color: white;
    text-align: center;
}

.profile-info {
    margin-bottom: 20px;
}

.profile-info p {
    margin: 10px 0;
}

.logout-button {
    background-color: lightblue;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.logout-button:hover {
    background-color: pink;
}

.change-username {
    background-color: lightblue;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.change-username button {
    background-color: lightblue;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.change-username button:hover {
    background-color: pink;
}

.change-username input {
    margin: 10px 0;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid lightblue;
}

.change-password {
    background-color: lightblue;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.change-password button {
    background-color: lightblue;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    color: white;
}

.change-password button:hover {
    background-color: pink;
}

.change-password input {
    margin: 10px 0;
    padding: 5px;
    border-radius: 4px;
    border: 1px solid lightblue;
}